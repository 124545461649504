define("discourse/plugins/chat/discourse/components/chat/message-creator/members-selector", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse-common/config/environment", "discourse-common/lib/debounce", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/message-creator/lib/chatables-loader", "discourse/plugins/chat/discourse/components/chat/message-creator/list", "discourse/plugins/chat/discourse/components/chat/message-creator/list-handler", "discourse/plugins/chat/discourse/components/chat/message-creator/members", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _object, _service, _dButton, _environment, _debounce, _discourseI18n, _chatablesLoader, _list, _listHandler, _members, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _MembersSelector;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let MembersSelector = _exports.default = (_class = (_MembersSelector = class MembersSelector extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "chatables", _descriptor2, this);
      _initializerDefineProperty(this, "filter", _descriptor3, this);
      _initializerDefineProperty(this, "highlightedMember", _descriptor4, this);
      _initializerDefineProperty(this, "highlightedChatable", _descriptor5, this);
      _defineProperty(this, "placeholder", _discourseI18n.default.t("chat.direct_message_creator.group_name"));
    }
    get items() {
      return this.chatables.filter(c1 => !this.highlightedMemberIds.includes(c1.model.id));
    }
    get highlightedMemberIds() {
      return this.args.members.map(u1 => u1.model.id);
    }
    highlightMember(member1) {
      this.highlightedMember = member1;
    }
    highlightChatable(chatable1) {
      this.highlightedChatable = chatable1;
    }
    selectChatable(chatable1) {
      if (!chatable1.enabled) {
        return;
      }
      const chatableMembers1 = chatable1.type === "group" ? chatable1.model.chat_enabled_user_count : 1;
      if (this.args.membersCount + chatableMembers1 > this.siteSettings.chat_max_direct_message_users) {
        return;
      }
      if (this.highlightedMemberIds.includes(chatable1.model.id)) {
        this.unselectMember(chatable1);
      } else {
        this.args.onChange?.([...this.args.members, chatable1]);
        this.highlightedChatable = this.items[0];
      }
      this.filter = "";
      this.focusFilterAction?.();
      this.highlightedMember = null;
    }
    registerFocusFilterAction(actionFn1) {
      this.focusFilterAction = actionFn1;
    }
    onFilter(event1) {
      this.searchHandler = (0, _debounce.default)(this, this.fetch, event1.target.value, _environment.INPUT_DELAY);
    }
    async fetch(term1) {
      this.highlightedMember = null;
      const loader1 = new _chatablesLoader.default(this);
      this.chatables = await loader1.search(term1, {
        includeCategoryChannels: false,
        includeDirectMessageChannels: false,
        excludedMembershipsChannelId: this.args.channel?.id
      });
      this.highlightedChatable = this.items[0];
    }
    unselectMember(removedMember1) {
      this.args.onChange?.(this.args.members.filter(member1 => member1 !== removedMember1));
      this.highlightedMember = null;
      this.highlightedChatable = this.items[0];
      this.focusFilterAction?.();
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <ListHandler
        @items={{this.items}}
        @highlightedItem={{this.highlightedChatable}}
        @onHighlight={{this.highlightChatable}}
        @onSelect={{this.selectChatable}}
      >
        <div class="chat-message-creator__add-members-header-container">
          <div class="chat-message-creator__add-members-header">
            <Members
              @filter={{this.filter}}
              @members={{@members}}
              @highlightedMember={{this.highlightedMember}}
              @onFilter={{this.onFilter}}
              @registerFocusFilterAction={{this.registerFocusFilterAction}}
              @onHighlightMember={{this.highlightMember}}
              @onSelectMember={{this.unselectMember}}
            />
  
            <DButton
              class="btn-flat chat-message-creator__add-members__close-btn"
              @action={{@cancel}}
              @icon="times"
            />
          </div>
        </div>
  
        <List
          @items={{this.items}}
          @highlightedItem={{this.highlightedChatable}}
          @onSelect={{this.selectChatable}}
          @onHighlight={{this.highlightChatable}}
          @maxReached={{@maxReached}}
          @membersCount={{@membersCount}}
        />
  
      </ListHandler>
    
  */
  {
    "id": "uuJp07O5",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@items\",\"@highlightedItem\",\"@onHighlight\",\"@onSelect\"],[[30,0,[\"items\"]],[30,0,[\"highlightedChatable\"]],[30,0,[\"highlightChatable\"]],[30,0,[\"selectChatable\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"chat-message-creator__add-members-header-container\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"chat-message-creator__add-members-header\"],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@filter\",\"@members\",\"@highlightedMember\",\"@onFilter\",\"@registerFocusFilterAction\",\"@onHighlightMember\",\"@onSelectMember\"],[[30,0,[\"filter\"]],[30,1],[30,0,[\"highlightedMember\"]],[30,0,[\"onFilter\"]],[30,0,[\"registerFocusFilterAction\"]],[30,0,[\"highlightMember\"]],[30,0,[\"unselectMember\"]]]],null],[1,\"\\n\\n          \"],[8,[32,2],[[24,0,\"btn-flat chat-message-creator__add-members__close-btn\"]],[[\"@action\",\"@icon\"],[[30,2],\"times\"]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[8,[32,3],null,[[\"@items\",\"@highlightedItem\",\"@onSelect\",\"@onHighlight\",\"@maxReached\",\"@membersCount\"],[[30,0,[\"items\"]],[30,0,[\"highlightedChatable\"]],[30,0,[\"selectChatable\"]],[30,0,[\"highlightChatable\"]],[30,3],[30,4]]],null],[1,\"\\n\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@members\",\"@cancel\",\"@maxReached\",\"@membersCount\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/members-selector.js",
    "scope": () => [_listHandler.default, _members.default, _dButton.default, _list.default],
    "isStrictMode": true
  }), _MembersSelector), _MembersSelector), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatables", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "filter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "highlightedMember", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "highlightedChatable", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "highlightMember", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "highlightMember"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "highlightChatable", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "highlightChatable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectChatable", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectChatable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerFocusFilterAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerFocusFilterAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFilter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetch", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "fetch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unselectMember", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "unselectMember"), _class.prototype)), _class);
});