define("discourse/plugins/chat/discourse/components/chat/message-creator/add-members", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax-error", "discourse-i18n", "truth-helpers/helpers/gte", "discourse/plugins/chat/discourse/components/chat/message-creator/members-count", "discourse/plugins/chat/discourse/components/chat/message-creator/members-selector", "@ember/template-factory", "@ember/component"], function (_exports, _component, _object, _service, _dButton, _ajaxError, _discourseI18n, _gte, _membersCount, _membersSelector, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _AddMembers;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AddMembers = _exports.default = (_class = (_AddMembers = class AddMembers extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "chatApi", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "toasts", _descriptor4, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor5, this);
      _initializerDefineProperty(this, "loadingSlider", _descriptor6, this);
    }
    get membersCount() {
      const userCount1 = this.args.members?.reduce((acc1, member1) => {
        if (member1.type === "group") {
          return acc1 + member1.model.chat_enabled_user_count;
        } else {
          return acc1 + 1;
        }
      }, 0);
      return userCount1 + (this.args.channel?.membershipsCount ?? 0);
    }
    async saveGroupMembers() {
      try {
        this.loadingSlider.transitionStarted();
        const usernames1 = this.args.members.filter(member1 => member1.type === "user").mapBy("model.username");
        const groups1 = this.args.members.filter(member1 => member1.type === "group").mapBy("model.name");
        await this.chatApi.addMembersToChannel(this.args.channel.id, {
          usernames: usernames1,
          groups: groups1
        });
        this.toasts.success({
          data: {
            message: _discourseI18n.default.t("saved")
          }
        });
        this.router.transitionTo("chat.channel", ...this.args.channel.routeModels);
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      } finally {
        this.loadingSlider.transitionEnded();
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-message-creator__add-members-container">
        <div class="chat-message-creator__add-members">
          <MembersCount
            @count={{this.membersCount}}
            @max={{this.siteSettings.chat_max_direct_message_users}}
          />
  
          <MembersSelector
            @channel={{@channel}}
            @members={{@members}}
            @onChange={{@onChangeMembers}}
            @close={{@close}}
            @cancel={{@cancel}}
            @membersCount={{this.membersCount}}
            @maxReached={{gte
              this.membersCount
              this.siteSettings.chat_max_direct_message_users
            }}
          />
  
          {{#if @members.length}}
            <div class="chat-message-creator__add-members-footer-container">
              <div class="chat-message-creator__add-members-footer">
                <DButton class="btn-flat" @label="cancel" @action={{@cancel}} />
  
                <DButton
                  class="btn-primary add-to-channel"
                  @label="chat.direct_message_creator.add_to_channel"
                  @action={{this.saveGroupMembers}}
                />
              </div>
            </div>
          {{/if}}
        </div>
      </div>
    
  */
  {
    "id": "tIRZb+jd",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message-creator__add-members-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-message-creator__add-members\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@count\",\"@max\"],[[30,0,[\"membersCount\"]],[30,0,[\"siteSettings\",\"chat_max_direct_message_users\"]]]],null],[1,\"\\n\\n        \"],[8,[32,1],null,[[\"@channel\",\"@members\",\"@onChange\",\"@close\",\"@cancel\",\"@membersCount\",\"@maxReached\"],[[30,1],[30,2],[30,3],[30,4],[30,5],[30,0,[\"membersCount\"]],[28,[32,2],[[30,0,[\"membersCount\"]],[30,0,[\"siteSettings\",\"chat_max_direct_message_users\"]]],null]]],null],[1,\"\\n\\n\"],[41,[30,2,[\"length\"]],[[[1,\"          \"],[10,0],[14,0,\"chat-message-creator__add-members-footer-container\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"chat-message-creator__add-members-footer\"],[12],[1,\"\\n              \"],[8,[32,3],[[24,0,\"btn-flat\"]],[[\"@label\",\"@action\"],[\"cancel\",[30,5]]],null],[1,\"\\n\\n              \"],[8,[32,3],[[24,0,\"btn-primary add-to-channel\"]],[[\"@label\",\"@action\"],[\"chat.direct_message_creator.add_to_channel\",[30,0,[\"saveGroupMembers\"]]]],null],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@channel\",\"@members\",\"@onChangeMembers\",\"@close\",\"@cancel\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/add-members.js",
    "scope": () => [_membersCount.default, _membersSelector.default, _gte.default, _dButton.default],
    "isStrictMode": true
  }), _AddMembers), _AddMembers), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "toasts", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "loadingSlider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "saveGroupMembers", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveGroupMembers"), _class.prototype)), _class);
});