define("discourse/plugins/chat/discourse/components/chat/message-creator/search", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax-error", "discourse-common/config/environment", "discourse-common/lib/debounce", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/message-creator/constants", "discourse/plugins/chat/discourse/components/chat/message-creator/lib/chatables-loader", "discourse/plugins/chat/discourse/components/chat/message-creator/list", "discourse/plugins/chat/discourse/components/chat/message-creator/list-handler", "discourse/plugins/chat/discourse/components/chat/message-creator/search-input", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _helper, _object, _didInsert, _service, _dButton, _ajaxError, _environment, _debounce, _discourseI18n, _constants, _chatablesLoader, _list, _listHandler, _searchInput, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _ChatMessageCreatorSearch;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatMessageCreatorSearch = _exports.default = (_class = (_ChatMessageCreatorSearch = class ChatMessageCreatorSearch extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "chatables", _descriptor3, this);
      _initializerDefineProperty(this, "highlightedChatable", _descriptor4, this);
      _initializerDefineProperty(this, "term", _descriptor5, this);
      _initializerDefineProperty(this, "loading", _descriptor6, this);
    }
    get items() {
      const items1 = [];
      if (this.loading) {
        return items1;
      }
      if (!this.term?.length) {
        items1.push({
          identifier: "new-group",
          type: "list-action",
          label: _discourseI18n.default.t("chat.new_message_modal.new_group_chat"),
          enabled: true,
          icon: "users",
          id: "new-group-chat"
        });
      }
      return [...items1, ...this.chatables];
    }
    prefillAddMembers(item1) {
      this.args.onChangeMode(_constants.MODES.new_group, [item1]);
    }
    highlightChatable(chatable1) {
      this.highlightedChatable = chatable1;
    }
    async selectChatable(item1) {
      switch (item1.type) {
        case "list-action":
          this.args.onChangeMode(_constants.MODES.new_group);
          break;
        case "user":
          if (!item1.enabled) {
            return;
          }
          await this.startOneToOneChannel(item1.model.username);
          break;
        case "group":
          if (!item1.enabled) {
            return;
          }
          this.args.onChangeMode(_constants.MODES.new_group, [item1]);
          break;
        default:
          this.router.transitionTo("chat.channel", ...item1.model.routeModels);
          this.args.close();
          break;
      }
    }
    onFilter(event1) {
      this.chatables = [];
      this.term = event1?.target?.value;
      this.searchHandler = (0, _debounce.default)(this, this.fetch, event1.target.value, _environment.INPUT_DELAY);
    }
    async fetch() {
      const loader1 = new _chatablesLoader.default(this);
      this.chatables = await loader1.search(this.term, {
        preloadChannels: !this.term
      });
      this.highlightedChatable = this.items[0];
    }
    async startOneToOneChannel(username1) {
      try {
        const channel1 = await this.chat.upsertDmChannel({
          usernames: [username1]
        });
        if (!channel1) {
          return;
        }
        this.args.close?.();
        this.router.transitionTo("chat.channel", ...channel1.routeModels);
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      }
    }
    async startGroupChannel(group1) {
      try {
        const channel1 = await this.chat.upsertDmChannel({
          groups: [group1]
        });
        if (!channel1) {
          return;
        }
        this.args.close?.();
        this.router.transitionTo("chat.channel", ...channel1.routeModels);
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <ListHandler
        @items={{this.items}}
        @highlightedItem={{this.highlightedChatable}}
        @onHighlight={{this.highlightChatable}}
        @onSelect={{this.selectChatable}}
        @onShifSelect={{this.prefillAddMembers}}
      >
        <div class="chat-message-creator__search-container">
          <div class="chat-message-creator__search">
            <div
              class="chat-message-creator__section"
              {{didInsert (fn this.fetch null)}}
            >
              <SearchInput @filter={{this.term}} @onFilter={{this.onFilter}} />
  
              <DButton
                class="btn-flat chat-message-creator__search-input__cancel-button"
                @icon="times"
                @action={{@close}}
              />
            </div>
  
            <List
              @items={{this.items}}
              @highlightedItem={{this.highlightedChatable}}
              @onSelect={{this.selectChatable}}
              @onHighlight={{this.highlightChatable}}
              @onShiftSelect={{this.prefillAddMembers}}
            />
          </div>
        </div>
      </ListHandler>
    
  */
  {
    "id": "QEOywO66",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@items\",\"@highlightedItem\",\"@onHighlight\",\"@onSelect\",\"@onShifSelect\"],[[30,0,[\"items\"]],[30,0,[\"highlightedChatable\"]],[30,0,[\"highlightChatable\"]],[30,0,[\"selectChatable\"]],[30,0,[\"prefillAddMembers\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"chat-message-creator__search-container\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"chat-message-creator__search\"],[12],[1,\"\\n          \"],[11,0],[24,0,\"chat-message-creator__section\"],[4,[32,1],[[28,[32,2],[[30,0,[\"fetch\"]],null],null]],null],[12],[1,\"\\n            \"],[8,[32,3],null,[[\"@filter\",\"@onFilter\"],[[30,0,[\"term\"]],[30,0,[\"onFilter\"]]]],null],[1,\"\\n\\n            \"],[8,[32,4],[[24,0,\"btn-flat chat-message-creator__search-input__cancel-button\"]],[[\"@icon\",\"@action\"],[\"times\",[30,1]]],null],[1,\"\\n          \"],[13],[1,\"\\n\\n          \"],[8,[32,5],null,[[\"@items\",\"@highlightedItem\",\"@onSelect\",\"@onHighlight\",\"@onShiftSelect\"],[[30,0,[\"items\"]],[30,0,[\"highlightedChatable\"]],[30,0,[\"selectChatable\"]],[30,0,[\"highlightChatable\"]],[30,0,[\"prefillAddMembers\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@close\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/search.js",
    "scope": () => [_listHandler.default, _didInsert.default, _helper.fn, _searchInput.default, _dButton.default, _list.default],
    "isStrictMode": true
  }), _ChatMessageCreatorSearch), _ChatMessageCreatorSearch), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chatables", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "highlightedChatable", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "term", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "prefillAddMembers", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "prefillAddMembers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "highlightChatable", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "highlightChatable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectChatable", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectChatable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFilter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetch", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "fetch"), _class.prototype)), _class);
});