define("discourse/plugins/chat/discourse/components/user-threads/index", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "discourse-common/helpers/i18n", "discourse-common/utils/decorators", "discourse/plugins/chat/discourse/components/channel-title", "discourse/plugins/chat/discourse/components/chat/list", "discourse/plugins/chat/discourse/components/chat-message-thread-indicator", "discourse/plugins/chat/discourse/components/thread-title", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _service, _i18n, _decorators, _channelTitle, _list, _chatMessageThreadIndicator, _threadTitle, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _UserThreads;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let UserThreads = _exports.default = (_class = (_UserThreads = class UserThreads extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "chatApi", _descriptor2, this);
      _initializerDefineProperty(this, "chatChannelsManager", _descriptor3, this);
    }
    get threadsCollection() {
      return this.chatApi.userThreads(this.handleLoadedThreads);
    }
    handleLoadedThreads(result1) {
      return result1.threads.map(threadObject1 => {
        const channel1 = this.chatChannelsManager.store(threadObject1.channel);
        const thread1 = channel1.threadsManager.add(channel1, threadObject1);
        const tracking1 = result1.tracking[thread1.id];
        if (tracking1) {
          thread1.tracking.mentionCount = tracking1.mention_count;
          thread1.tracking.unreadCount = tracking1.unread_count;
        }
        return thread1;
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <List
        @collection={{this.threadsCollection}}
        class="c-user-threads"
        as |list|
      >
        <list.Item as |thread|>
          <div class="c-user-thread" data-id={{thread.id}}>
            <ThreadTitle @thread={{thread}} />
            <ChannelTitle @channel={{thread.channel}} />
            <ThreadIndicator
              @message={{thread.originalMessage}}
              @interactiveUser={{false}}
              @interactiveThread={{false}}
              tabindex="-1"
            />
          </div>
        </list.Item>
        <list.EmptyState>
          <div class="empty-state-threads">
            {{i18n "chat.empty_state.my_threads"}}
          </div>
        </list.EmptyState>
      </List>
    
  */
  {
    "id": "IGm1QoLb",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"c-user-threads\"]],[[\"@collection\"],[[30,0,[\"threadsCollection\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,1,[\"Item\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"c-user-thread\"],[15,\"data-id\",[30,2,[\"id\"]]],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@thread\"],[[30,2]]],null],[1,\"\\n          \"],[8,[32,2],null,[[\"@channel\"],[[30,2,[\"channel\"]]]],null],[1,\"\\n          \"],[8,[32,3],[[24,\"tabindex\",\"-1\"]],[[\"@message\",\"@interactiveUser\",\"@interactiveThread\"],[[30,2,[\"originalMessage\"]],false,false]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[2]]]]],[1,\"\\n      \"],[8,[30,1,[\"EmptyState\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"empty-state-threads\"],[12],[1,\"\\n          \"],[1,[28,[32,4],[\"chat.empty_state.my_threads\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n  \"]],[\"list\",\"thread\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/user-threads/index.js",
    "scope": () => [_list.default, _threadTitle.default, _channelTitle.default, _chatMessageThreadIndicator.default, _i18n.default],
    "isStrictMode": true
  }), _UserThreads), _UserThreads), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chatChannelsManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "threadsCollection", [_tracking.cached], Object.getOwnPropertyDescriptor(_class.prototype, "threadsCollection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleLoadedThreads", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "handleLoadedThreads"), _class.prototype)), _class);
});