define("discourse/plugins/chat/discourse/components/chat-message-in-reply-to-indicator", ["exports", "@glimmer/component", "@ember/routing", "@ember/service", "discourse/helpers/replace-emoji", "discourse-common/helpers/d-icon", "discourse-common/helpers/html-safe", "discourse/plugins/chat/discourse/components/chat-emoji-avatar", "discourse/plugins/chat/discourse/components/chat-user-avatar", "@ember/template-factory", "@ember/component"], function (_exports, _component, _routing, _service, _replaceEmoji, _dIcon, _htmlSafe, _chatEmojiAvatar, _chatUserAvatar, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _ChatMessageInReplyToIndicator;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatMessageInReplyToIndicator = _exports.default = (_class = (_ChatMessageInReplyToIndicator = class ChatMessageInReplyToIndicator extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
    }
    get route() {
      if (this.hasThread) {
        return "chat.channel.thread";
      } else {
        return "chat.channel.near-message";
      }
    }
    get model() {
      if (this.hasThread) {
        return [...this.args.message.channel.routeModels, this.args.message.thread.id];
      } else {
        return [...this.args.message.channel.routeModels, this.args.message.inReplyTo.id];
      }
    }
    get hasThread() {
      return this.args.message?.channel?.threadingEnabled && this.args.message?.thread?.id;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if @message.inReplyTo}}
        <LinkTo
          @route={{this.route}}
          @models={{this.model}}
          class="chat-reply is-direct-reply"
        >
          {{dIcon "share" title="chat.in_reply_to"}}
  
          {{#if @message.inReplyTo.chatWebhookEvent.emoji}}
            <ChatEmojiAvatar
              @emoji={{@message.inReplyTo.chatWebhookEvent.emoji}}
            />
          {{else}}
            <ChatUserAvatar @user={{@message.inReplyTo.user}} />
          {{/if}}
  
          <span class="chat-reply__excerpt">
            {{replaceEmoji (htmlSafe @message.inReplyTo.excerpt)}}
          </span>
        </LinkTo>
      {{/if}}
    
  */
  {
    "id": "zfH8D9bT",
    "block": "[[[1,\"\\n\"],[41,[30,1,[\"inReplyTo\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"chat-reply is-direct-reply\"]],[[\"@route\",\"@models\"],[[30,0,[\"route\"]],[30,0,[\"model\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[32,1],[\"share\"],[[\"title\"],[\"chat.in_reply_to\"]]]],[1,\"\\n\\n\"],[41,[30,1,[\"inReplyTo\",\"chatWebhookEvent\",\"emoji\"]],[[[1,\"          \"],[8,[32,2],null,[[\"@emoji\"],[[30,1,[\"inReplyTo\",\"chatWebhookEvent\",\"emoji\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,3],null,[[\"@user\"],[[30,1,[\"inReplyTo\",\"user\"]]]],null],[1,\"\\n\"]],[]]],[1,\"\\n        \"],[10,1],[14,0,\"chat-reply__excerpt\"],[12],[1,\"\\n          \"],[1,[28,[32,4],[[28,[32,5],[[30,1,[\"inReplyTo\",\"excerpt\"]]],null]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@message\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-message-in-reply-to-indicator.js",
    "scope": () => [_routing.LinkTo, _dIcon.default, _chatEmojiAvatar.default, _chatUserAvatar.default, _replaceEmoji.default, _htmlSafe.default],
    "isStrictMode": true
  }), _ChatMessageInReplyToIndicator), _ChatMessageInReplyToIndicator), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});